import { useEffect, useRef, useState } from 'react';
import { IFrame, Container } from './styles';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import type { Iframe as TIframe } from 'server/repository/hygraph/generated/graphqlTypes';
import { TrackingService } from 'service/tracking/trackingService';
interface IIframeComponent extends ISharedTemplateProps {
  templateProps: TIframe & { iframeUrl: string };
}

export const ADVENT_CALENDAR_KEYWORDS = {
  ADVENT_CALENDAR: 'Adventcalendar',
  ADVENT_CALENDAR_PARTICIPATION: 'AdventCalendar_Participation',
};

export const IframeAdventCalendar = ({
  templateProps: { iframeWidth, iframeHeight, iframeUrl, iframeType, alignment },
}: IIframeComponent) => {
  // TODO: later thous could be fetched only from router but for now
  // it causes a bug because it's always undefined
  const [dynamicUrl, setDynamicUrl] = useState<string | undefined>(undefined);
  const elementRef = useRef<HTMLDivElement>(null);
  const searchParamsFromIframeUrl = iframeUrl.split('?');
  const validDoor = dynamicUrl && Number(dynamicUrl) > 0 && Number(dynamicUrl) < 25;
  const DOOR_PARAM = validDoor ? `&door=${dynamicUrl}` : '';

  // url in the browser is e.g.:
  // https://www.oetker.de/inspiration/anlaesse/weihnachten/adventskalender?door=8

  const iframeStrToUrl = new URL(iframeUrl);
  const searchParamsFromString = new URLSearchParams(iframeStrToUrl.search);

  const COUNTRY_PARAM = searchParamsFromString.get('country') || '';
  const BRAND_PARAM = searchParamsFromString.get('brand') || '';

  // if doorFromUrl is zero 1:1 show the entered url from Hypraph
  // otherwise show a url like this:
  // https://cloud.email.oetker.com/AdventCalendar_Participation?country=DE&brand=dr.+oetker

  const BASE_URL =
    searchParamsFromIframeUrl
      .at(0)
      ?.replace(
        ADVENT_CALENDAR_KEYWORDS.ADVENT_CALENDAR,
        ADVENT_CALENDAR_KEYWORDS.ADVENT_CALENDAR_PARTICIPATION,
      ) || iframeUrl;

  /* Events available
    iframeScrollTop - this happens when any quiz (door) page is loaded and success page (boolean)
    quizPage - this happens when any quiz (door) page is loaded (boolean)
    successPage - this happens when success page is loaded (boolean)
  */

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const doorFromUrl = urlParams.get('door');

    const dynamicUrl = doorFromUrl
      ? encodeURI(`${BASE_URL}?country=${COUNTRY_PARAM}&brand=${BRAND_PARAM}${DOOR_PARAM}`)
      : iframeUrl;
    setDynamicUrl(dynamicUrl);

    const receiveMessage = (event: { data: any }) => {
      if (event.data.iframeScrollTop && !doorFromUrl) {
        scrollToIframeTop();
      }

      if (event.data.quizPage) {
        /*
          IMPLEMENT TRACKING
        */
        TrackingService.trackEvent({
          event: 'advent_calendar_quiz_page',
        });
      }

      if (event.data.successPage) {
        /*
          IMPLEMENT TRACKING
        */
        TrackingService.trackEvent({
          event: 'advent_calendar_success_page',
        });
        scrollToIframeTop();
      }
    };

    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [dynamicUrl]);

  const scrollToIframeTop = () => {
    const iframeTop = elementRef.current;
    if (iframeTop) {
      iframeTop.scrollIntoView({ behavior: 'auto' });
    }
  };

  return (
    <Container $alignment={alignment ?? 'center'} ref={elementRef}>
      <span className="hidden">IframeAdventCalendar</span>
      <IFrame
        $iframeType={iframeType}
        $iframeWidth={iframeWidth || '100vw'}
        $iframeHeight={iframeHeight || '2500px'}
        src={dynamicUrl}
        title="fullPage"
        allow="geolocation"
        allowFullScreen
      />
    </Container>
  );
};
