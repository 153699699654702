import type { Iframe as TIframe } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { ADVENT_CALENDAR_KEYWORDS, IframeAdventCalendar } from './IframeAdventCalendar';
import { Container, IFrame } from './styles';

interface IIframeComponent extends ISharedTemplateProps {
  templateProps: TIframe & { iframeUrl: string };
}

export const Iframe = ({ templateProps }: IIframeComponent) => {
  const { alignment, iframeHeight, iframeType, iframeUrl, iframeWidth } = templateProps;
  const isAdventCalendarActive = iframeUrl.includes(
    ADVENT_CALENDAR_KEYWORDS.ADVENT_CALENDAR ||
      ADVENT_CALENDAR_KEYWORDS.ADVENT_CALENDAR_PARTICIPATION,
  );

  if (isAdventCalendarActive) {
    return <IframeAdventCalendar templateProps={templateProps} />;
  }

  return (
    <Container $alignment={alignment ?? 'center'}>
      <span className="hidden">IFrame</span>
      <IFrame
        $iframeType={iframeType}
        $iframeWidth={iframeWidth || '100%'}
        $iframeHeight={iframeHeight || '2500px'}
        src={iframeUrl}
        title="fullPage"
        allow="geolocation"
        allowFullScreen
      />
    </Container>
  );
};
