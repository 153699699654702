import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const IFrame = styled.iframe<{
  $iframeType?: string;
  $iframeWidth?: string;
  $iframeHeight?: string;
}>`
  ${(props) =>
    props.$iframeType === 'responsive'
      ? `width:100%;max-width:${props.$iframeWidth}; height:${props.$iframeHeight}`
      : `width:${props.$iframeWidth}; height:${props.$iframeHeight}`}
`;

export const Container = tw.div<{ $alignment?: string }>`
    w-full
    flex
    mt-11
    ${(props) => (props.$alignment === 'left' ? 'justify-start' : 'justify-center')}
`;
